import React, { ReactNode, useMemo, useState } from 'react';
import FooterLinkContext from './FooterLinkContext';

const FooterLinkProvider = ({ children }: { children: ReactNode }) => {
  const [contactDrawer, setContactDrawer] = useState(false);
  const [faqDrawer, setFaqDrawer] = useState(false);

  const state = useMemo(
    () => ({
      faqDrawer,
      contactDrawer,
      setContactDrawer,
      setFaqDrawer,
    }),
    [contactDrawer, faqDrawer]
  );

  return (
    <FooterLinkContext.Provider value={state}>
      {children}
    </FooterLinkContext.Provider>
  );
};

export default FooterLinkProvider;
