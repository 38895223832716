import React, { ChangeEvent, useState } from 'react';
import { useExpositions } from '@brainstud/academy-api';
import {
  Button,
  Callout,
  Panel,
  Search,
  Table,
  Td,
  Th,
  Tr,
} from '@brainstud/ui';
import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import classNames from 'classnames/bind';
import { Link, Loading } from 'Components';
import { GridListToggle } from 'Components/GridListToggle';
import { FEATURES } from 'Config/features';
import { usePortalFeatureCheck } from 'Hooks';
import { ExpositionModalForm } from 'Modules/blended-learning-catalog-panel/Modals';
import { useModals } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { DateFormatter } from 'Utils/DateFormatHelper';
import { FullMediaCard } from '../../../Components';
import { ExpositionActions } from './ExpositionActions';
import styles from './ExpositionsIndexView.module.css';

const cx = classNames.bind(styles);

export const ExpositionsIndexView = () => {
  const [t] = useTranslator();
  const { openModal } = useModals();
  const isFileUploadEnabled = usePortalFeatureCheck(FEATURES.ACCOUNT_FILES);

  const [search, setSearch] = useState<string>('');
  const [isGridView, setIsGridView] = useState(true);
  const [{ data: expositions }, { isLoading }] = useExpositions({
    ...(search
      ? {
          filter: {
            title: search,
          },
        }
      : {}),
    include: ['shareables'],
    showStoredFilesCount: isFileUploadEnabled,
  });

  return (
    <div className={styles.base}>
      <div className={styles['filter-bar']}>
        <Search
          className={cx(styles.searchInput)}
          onClose={() => {
            setSearch('');
          }}
          onChange={(v: ChangeEvent<HTMLInputElement>) =>
            setSearch(v.target.value || '')
          }
          placeholder={t('views.portfolio.search')}
        />
        <div>
          <Button
            outline
            type="button"
            onClick={() => openModal(ExpositionModalForm)}
            className={styles['add-button']}
          >
            <Add />
            <span>{t('views.portfolio.expositions.add_exposition')}</span>
          </Button>
          <GridListToggle onChange={setIsGridView} value={isGridView} />
        </div>
      </div>

      {isLoading ? (
        <Loading />
      ) : expositions.length === 0 ? (
        <Callout info icon={false}>
          <section>
            <p>
              <strong>
                {t('views.portfolio.expositions.no_expositions_created')}
              </strong>
            </p>
            <span>
              {t(
                'views.portfolio.expositions.create_new_exposition_intruction'
              )}
            </span>
          </section>
        </Callout>
      ) : (
        <div>
          {isGridView ? (
            <Grid container spacing={2}>
              {expositions.map((exposition) => (
                <Grid
                  key={exposition.id}
                  item
                  xs={6}
                  sm={4}
                  md={3}
                  className={styles.relative}
                >
                  <Link
                    href={`/portfolio/expositions/${exposition.id}`}
                    className={cx(styles['no-underline'])}
                  >
                    <FullMediaCard
                      title={exposition.title}
                      subtitle={
                        <>
                          <strong>
                            {exposition.meta?.learningObjectCount}
                          </strong>
                          {` ${t('assignments')} `}
                          {isFileUploadEnabled && (
                            <>
                              <strong>
                                {exposition.meta?.storedFilesCount}
                              </strong>
                              {` ${t('files')}`}
                            </>
                          )}
                        </>
                      }
                      background={exposition.image}
                    >
                      <ExpositionActions exposition={exposition} />
                    </FullMediaCard>
                  </Link>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Panel>
              <Table>
                <Table.THead>
                  <Tr>
                    <Th>{t('views.portfolio.expositions.table.title')}</Th>
                    <Th>{t('views.portfolio.expositions.table.assigments')}</Th>
                    <Th>{t('views.portfolio.expositions.table.files')}</Th>
                    <Th>{t('views.portfolio.expositions.table.created_at')}</Th>
                  </Tr>
                </Table.THead>
                <Table.TBody>
                  {expositions.map((exposition) => (
                    <Tr
                      key={exposition.id}
                      href={`/portfolio/expositions/${exposition.id}`}
                    >
                      <Td href={`/portfolio/expositions/${exposition.id}`}>
                        {exposition.title}
                      </Td>
                      <Td>{exposition.meta?.learningObjectCount}</Td>
                      <Td>{exposition.meta?.storedFilesCount || 0}</Td>
                      <Td>{DateFormatter.toDateTime(exposition.createdAt)}</Td>
                    </Tr>
                  ))}
                </Table.TBody>
              </Table>
            </Panel>
          )}
        </div>
      )}
    </div>
  );
};
