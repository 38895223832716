import React from 'react';
import Base from './Base';

const WithBase = (Component: any) => (props: any) => (
  <Base>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Component {...props} />
  </Base>
);

export default WithBase;
