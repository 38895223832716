import React, { useCallback, useMemo, useState } from 'react';
import {
  Course,
  useAnnouncements,
  useMe,
  usePortalCourses,
} from '@brainstud/academy-api';
import { Container, Loading } from 'Components';
import { AnnouncementsSlider } from 'Components/AnnouncementDisplay/AnnouncementsSlider';
import { useEnvironmentProvider } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { CourseListProvider } from '../../Providers';
import { Catalog } from './Sections';
import styles from './HomeView.module.css';

const HomeView = () => {
  const [t] = useTranslator();
  const [{ data: courses }, { isLoading }] = usePortalCourses({
    include: ['lti_resource_links'],
  });
  const [linkProductCategories, setLinkProductCategories] = useState<string>();
  const [me] = useMe();

  const { environment } = useEnvironmentProvider();
  const [announcements] = useAnnouncements({
    filter: { active: true },
    sort: ['-type'],
  });

  const environmentAnnouncements = announcements.filter(
    (announcement) =>
      !announcement.roles ||
      (environment && announcement.roles.includes(environment?.toString()))
  );

  const enrolledCourses = useMemo(
    () => courses.filter((course) => !!course.enrollment),
    [courses]
  );

  const notEnrolledCourses = useMemo(
    () => courses.filter((course) => !course.enrollment),
    [courses]
  );

  const filterOnCourseType = useCallback(
    (courseList: Course[], types: string[]) =>
      courseList.filter(
        (course) => types.indexOf(course.status.toLowerCase()) !== -1
      ),
    []
  );

  const comingSoon = filterOnCourseType(courses, ['coming_soon']);
  const published = filterOnCourseType(notEnrolledCourses, [
    'published',
    'live',
  ]);

  const isLoggedIn = useMemo(() => Boolean(me?.account), [me]);
  const allCourses = useMemo(
    () => [...((isLoggedIn && enrolledCourses) || []), ...published],
    [isLoggedIn, enrolledCourses, published]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <section className={styles.sectionDark} id="scroll">
      {!!announcements && (
        <Container className={styles.announcementsContainer}>
          <AnnouncementsSlider
            darkBackground
            announcements={environmentAnnouncements}
          />
        </Container>
      )}

      <CourseListProvider
        uniqueStorageId="homeview_courses"
        courses={allCourses}
        onCategoryChange={setLinkProductCategories}
      >
        <Catalog title={<h1>{t('students.allCourses')}</h1>} />
      </CourseListProvider>

      {comingSoon.length !== 0 && (
        <CourseListProvider
          uniqueStorageId="coming_soon"
          courses={comingSoon}
          quickStart={false}
        >
          <Catalog
            headless
            category={linkProductCategories}
            // eslint-disable-next-line react/no-unstable-nested-components
            title={(category) => (
              <h3>{`${t('components.catalog.coming_soon')} ${category?.labelPlural?.toLowerCase()}`}</h3>
            )}
          />
        </CourseListProvider>
      )}
    </section>
  );
};

export default HomeView;
