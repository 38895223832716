import React from 'react';
import { ErrorBoundary } from 'Providers/ErrorDataProvider/ErrorBoundary';
import styles from './Base.module.css';

interface BaseProps {
  children: React.ReactNode;
}

const Base = ({ children }: BaseProps) => (
  <div className={styles.base}>
    <ErrorBoundary container>{children}</ErrorBoundary>
  </div>
);

export default Base;
