import { createContext, Dispatch, SetStateAction } from 'react';

interface IFooterLinkContext {
  contactDrawer: boolean;
  faqDrawer: boolean;
  setContactDrawer: Dispatch<SetStateAction<boolean>>;
  setFaqDrawer: Dispatch<SetStateAction<boolean>>;
}

export default createContext<IFooterLinkContext>({
  contactDrawer: false,
  faqDrawer: false,
  setContactDrawer: () => {
    throw Error('App Context not initialized yet.');
  },
  setFaqDrawer: () => {
    throw Error('App Context not initialized yet.');
  },
});
