import React, { useCallback, useMemo } from 'react';
import { useMe } from '@brainstud/academy-api';
import { Loading } from 'Components';
import { AboutBlock, PartnersBlock, VideoBlock } from '../Components';
import { ParamArray } from '../Components/Blocks/Blocks';
import { Footer } from '../Layouts';
import FooterLinkProvider from '../Providers/FooterLinkProvider/FooterLinkProvider';
import { Hero } from './HomeView/Sections';
import { HomeView } from './index';

/**
 * MainView.
 *
 * Shows the main page of the BLC
 */
export const MainView = () => {
  const [me, { isLoading }] = useMe();

  const account = me?.account?.();
  const portal = useMemo(() => me?.portal?.(), [me]);

  const getPortalParameter = useCallback(
    (name) =>
      JSON.parse(
        portal?.parameters?.find((param: ParamArray) => param?.type === name)
          ?.data || null
      ),
    [portal]
  );

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {!account && portal?.hero && <Hero />}

      {!account && getPortalParameter('videos') && (
        <VideoBlock videos={getPortalParameter('videos')} />
      )}
      {!account && getPortalParameter('about') && (
        <AboutBlock
          title={getPortalParameter('about').title}
          content={getPortalParameter('about')?.content}
        />
      )}
      <HomeView />
      {!account && getPortalParameter('partners') && (
        <PartnersBlock partners={getPortalParameter('partners')} />
      )}
      <FooterLinkProvider>
        <Footer />
      </FooterLinkProvider>
    </>
  );
};
